<template>
  <div id="app">
    <!-- Header -->
    <header class="header">
      <img src="@/assets/logo.png" alt="Logo" class="logo" height="100" width="auto" />
    </header>

    <!-- Main Content -->
    <div class="app-container">
      <ImageUpload @uploaded="handleUploadedImage" v-if="!loading && !suggestions.length" />
      <div v-if="loading" class="loading">Processing your image...</div>
      <ResultsPage
        v-if="suggestions.length"
        :suggestions="suggestions"
        @restart="handleRestart"
      />
      <br/><br/>
      <TrendingItems />
    </div>

    <!-- Footer -->
    <footer class="footer">
      <p>© 2024 MyRedesignedRoom</p><br/><p>All rights reserved.</p>
      <a href="#" @click="showLearnMore = !showLearnMore">
        {{ showLearnMore ? 'Hide Privacy Policy & Terms of Service' : 'Privacy Policy & Terms of Service' }}
      </a>
      <LearnMore v-if="showLearnMore" />
      <center><caption>v.242310.2</caption></center>
    </footer>
  </div>
</template>

<script>
import ImageUpload from './components/ImageUpload.vue';
import ResultsPage from './components/ResultsPage.vue';
import LearnMore from './components/LearnMore.vue';
import TrendingItems from './components/TrendingItems.vue';

export default {
  components: {
    ImageUpload,
    ResultsPage,
    LearnMore,
    TrendingItems
  },
  data() {
    return {
      showLearnMore: false,
      suggestions: [],
      loading: false // Add loading state
    };
  },
  methods: {
    async handleUploadedImage(suggestions) {
    this.suggestions = suggestions;
    console.log("suggestionsForResult:", this.suggestions);
},
    handleRestart() {
      this.suggestions = []; // Clear suggestions
      this.loading = false;  // Reset loading state
    }
  },
  watch: {
    suggestions(newValue) {
      if (!newValue.length && !this.loading) {
        this.loading = false; // Reset loading when there are no suggestions and not in loading state
      }
    }
  }
};
</script>

<style scoped>
#app {
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  text-align: center;
}

/* Header */
.header {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: white;
}

.logo {
  font-size: 1.8rem;
  font-weight: 700;
  color: #333;
}

/* Main Content */
.app-container {
  max-width: 900px;
  margin: 50px auto;
}

.loading {
  font-size: 1.5rem;
  color: #ff6f61;
  padding: 50px 0;
}

/* Footer */
.footer {
  padding: 20px 0;
  text-align: center;
  margin-top: 100px;
}

.footer p {
  color: #666;
  font-size: 1rem;
}
</style>
