<template>
  <div class="upload-container">
    <h3>Unlock Stunning Room Designs with the Power of AI!</h3>
    <p>Get personalized design suggestions instantly.</p>
    <h5>Try it out:</h5>

    <!-- Improved File Upload Component -->
    <file-pond
      v-if="!loading"
      name="test"
      ref="pond"
      max-files="1"
      :disabled="loading"
      class-name="my-pond"
      label-idle="Drag and drop your image here or <span class='filepond--label-action'>Browse</span>"
      allow-multiple="false"
      :accepted-file-types="['image/jpeg', 'image/png']"
      v-on:init="handleFilePondInit"
      @addfile="handleUpload"
    />

    <!-- Success Message -->
    <div v-if="uploadSuccess" class="success-message">
      Image uploaded successfully! Processing...
    </div>

    <!-- Loader -->
    <div v-if="loading" class="loading">
      <div class="spinner"></div>
      <p>Hang tight! Your design suggestions are being generated...</p>
    </div>
  </div>
</template>

<script>
import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

export default {
  components: {
    FilePond
  },
  data() {
    return {
      uploadSuccess: false, // Track success message visibility
      loading: false // Track loading state
    };
  },
  methods: {
    handleFilePondInit() {
      console.log('FilePond has initialized');
    },
    async handleUpload(error, file) {
    this.loading = true; // Start loading state

    if (error) {
      console.error("FilePond error:", error);
      this.loading = false; // Stop loading state
      return;
    }

    const reader = new FileReader();

    reader.onloadend = async () => {
      if (!reader.result) {
        console.error("FileReader failed to read the file.");
        this.loading = false; // Stop loading state
        return;
      }

      const base64String = reader.result.split(',')[1]; // Extract base64 data from result

      try {
        const apiKey = process.env.VUE_APP_AWS_GATEWAY_Redesigned;

        console.log("Making POST request to API with base64 image data.");

        const response = await fetch('https://9a88qbktpd.execute-api.us-east-2.amazonaws.com/Prod/', {
          method: 'POST',
          headers: { 'content-type': 'application/json', 'x-api-key': apiKey },
          body: JSON.stringify({ image: base64String })
        });

        console.log(`Response status: ${response.status}`);

        if (!response.ok) {
          console.error(`Request failed with status: ${response.status}, status text: ${response.statusText}`);
          return;
        }

        const rawData = await response.json(); // First parse the response as JSON
        console.log("Raw API Response:", rawData); // Log the raw response

        // Check if the body is stringified and parse it
        let data;
        if (typeof rawData.body === 'string') {
          try {
            data = JSON.parse(rawData.body); // Parse the stringified JSON body
            console.log("Parsed data from stringified body:", data); // Log parsed data
          } catch (err) {
            console.error("Error parsing the stringified body:", err);
            this.loading = false;
            return;
          }
        } else {
          data = rawData.body;
          console.log("Parsed data from object body:", data); // Log parsed data
        }

        // Ensure choices[0].message.content exists before using it
        if (data.choices && data.choices.length > 0 && data.choices[0].message) {
          const messageContent = data.choices[0].message.content;
          console.log("Parsed message content:", messageContent); // Log the content

          // Emit the plain text message content instead of trying to parse it as JSON
          this.$emit('uploaded', messageContent); 
          this.uploadSuccess = true; // Show success message
          console.log("Image uploaded successfully.");
        } else {
          console.error("Unexpected response structure:", data);
        }

        this.loading = false; // Always stop the loading state
      } catch (err) {
        console.error("Failed to upload image. Error details:", err);
        this.loading = false;
      } finally {
        console.log("Upload attempt completed, setting loading to false.");
        this.loading = false;
      }
    };

    reader.onerror = (e) => {
      console.error("FileReader encountered an error:", e);
      this.loading = false; // Stop loading state
    };

    if (file && file.file) {
      console.log("Starting to read file:", file.file.name);
      reader.readAsDataURL(file.file);
    } else {
      console.error("No valid file to read.");
      this.loading = false; // Stop loading state
    }
  }
  }
};

</script>

<style scoped>
.upload-container {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  padding: 40px 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
}

h5 {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 20px;
}

.my-pond {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  border: 2px dashed #6f42c1;
  border-radius: 10px;
  padding: 30px;
  background-color: #fff;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.my-pond:hover {
  background-color: #fafafa;
  border-color: #5a34a1;
}

.success-message {
  margin-top: 20px;
  color: #28a745;
  font-size: 1.2rem;
  font-weight: 600;
  animation: fadeIn 0.5s ease;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #6f42c1;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

ol.steps-list {
  text-align: left;
  padding-left: 0;
  margin-top: 30px;
}

ol li {
  margin-bottom: 15px;
  font-size: 1.1rem;
  color: #333;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
</style>
