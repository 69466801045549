import axios from 'axios';

export const fetchAmazonProducts = async (searchQuery) => {
    try {
        // Make a request to your backend API (e.g., Vercel Serverless Function)
        const response = await axios.get(`/api/amazon-products?q=${searchQuery}`);
        
        // The backend will handle the Amazon API request and return the result
        return response.data;
    } catch (error) {
        console.error('Error fetching products from backend:', error);
        return [];
    }
};

export const fetchTrendingHomeItems = async () => {
    try {
        const response = await axios.get(`/api/amazon-products-trending`);
        return response.data;
    } catch (error) {
        console.error('Error fetching trending home items:', error);
        return [];
    }
};