<template>
  <div class="results-container">
    <h2>Your personalized recommendations:</h2>

    <div class="button-container">
      <button class="action-button save-button" @click="saveSuggestions">
        <i class="fas fa-download"></i> Save
      </button>
      <button class="action-button share-button" @click="shareSuggestions">
        <i class="fas fa-share-alt"></i> Share
      </button>
      <button class="action-button restart-button" @click="$emit('restart')">
        <i class="fas fa-sync-alt"></i> Start over
      </button>
    </div>

    <hr />
    <br />

    <!-- Display suggestions list -->
    <div
      v-for="(suggestion, index) in suggestionsList"
      :key="suggestion.number"
      class="suggestion-card"
    >
      <h3 style="text-align: left;" v-if="suggestion.title">{{ suggestion.title }}</h3>
      <p style="text-align: left;" v-if="suggestion.description">{{ suggestion.description }}</p>

      <!-- Button to fetch related products -->
      <button class="fetch-button" v-if="!suggestion.amazonProducts && !suggestion.loading" @click="fetchRelatedProducts(suggestion, index)">
        <i class="fas fa-shopping-cart"></i> Show Related Products
      </button>

      <!-- Loading indicator only shows after they click fetch -->
      <div v-if="suggestion.loading" class="loading-indicator">
        <p>Loading related products...</p>
        <div class="spinner"></div>
      </div>

      <!-- Display fetched Amazon products for each suggestion -->
      <div v-if="suggestion.amazonProducts && suggestion.amazonProducts.length" class="amazon-products">
        <h4>Related Products on Amazon</h4>
        <!-- Carousel Wrapper -->
          <carousel :items-to-show="3.5" :arrows="true" :navigationEnabled="true" class="amazon-carousel">
            <slide v-for="product in suggestion.amazonProducts" :key="product.ASIN">
              <div class="amazon-product-card">
                <img :src="product.Images.Primary.Medium.URL" alt="Product Image" class="product-image" />
                <div class="product-info">
                  <!-- Shortened product title with hover for full title -->
                  <p class="product-title" :title="product.ItemInfo.Title.DisplayValue">
                    {{ truncateTitle(product.ItemInfo.Title.DisplayValue, 50) }}
                  </p>
                  <!-- Price section -->
                  <p v-if="product.Offers && product.Offers.Listings && product.Offers.Listings.length">
                    Price: <span class="product-price">{{ product.Offers.Listings[0].Price.DisplayAmount }}</span>
                  </p>
                  <p v-else>Price information is not available</p>
                  <!-- Call to action button -->
                  <a :href="product.DetailPageURL" target="_blank" class="view-product-link">Shop Now</a>
                </div>
              </div>
            </slide>
          </carousel>

      </div>

    </div>
  </div>
</template>

<script>
import { fetchAmazonProducts } from '../amazonApiHelper.js';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

export default { 
  components: {
    Carousel,
    Slide
  },
  props: {
    suggestions: {
      type: String,
      required: true, // Ensure suggestions are passed correctly as a prop
    },
  },
  data() {
    return {
      initialText: '',
      suggestionsList: [], // Holds the parsed suggestions
      amazonProducts: [], // Holds Amazon product data
    };
  },
  mounted() {
    if (this.suggestions) {
      const parsedData = this.parseSuggestions(this.suggestions);
      this.suggestionsList = parsedData.suggestions || [];
      this.initialText = parsedData.initialText;
    }
  },
  methods: {
    truncateTitle(title, maxLength) {
      return title.length > maxLength ? title.slice(0, maxLength) + '...' : title;
    },
    parseSuggestions(text) {
      if (!text) {
        return {
          initialText: '',
          suggestions: [],
        };
      }

      const initialText = '';  // No need for initial text extraction in this case
      const suggestionsText = text.trim();

      // Regex to match each suggestion with ttl, desc, and Search
      const suggestionRegex = /ttl:\s*(.*?)\s*desc:\s*(.*?)\s*Search:\s*(.*)/g;
      let suggestions = [];
      let match;

      while ((match = suggestionRegex.exec(suggestionsText)) !== null) {
        // Remove any square brackets and bold syntax from title
        const title = match[1].trim().replace(/\*\*/g, '').replace(/\[|\]/g, ''); 

        // Remove square brackets from the description if necessary (you can skip this if not needed)
        const description = match[2].trim().replace(/\[|\]/g, '');

        // Remove square brackets from each amazonSearchTerm
        const amazonSearchTerms = match[3]
          .split(',')
          .map(term => term.trim().replace(/\[|\]/g, ''));

        // Push the cleaned up data into suggestions
        suggestions.push({ title, description, amazonSearchTerms });
      }

      if (suggestions.length === 0) {
        console.log('No suggestions found');
        suggestions.push({
          title: "Hmm...We couldn't generate any design suggestions.",
          description: "It looks like the image you uploaded may not be of a room. Please try again with a clear photo of the space you want to improve.",
          amazonSearchTerms: [],
        });
      }

      return {
        initialText,
        suggestions,
      };
    },
    async fetchRelatedProducts(suggestion, index) {
      this.suggestionsList[index].loading = true;

      try {
        // Join all the search terms with spaces or commas (based on how the API expects them)
        const searchQuery = suggestion.amazonSearchTerms.length > 0 
          ? suggestion.amazonSearchTerms.join(' ')  // or use ',' if necessary
          : suggestion.title;

        const products = await fetchAmazonProducts(searchQuery);
        
        if (products.length) {
          this.suggestionsList[index].amazonProducts = products;
        } else {
          console.log(`No products found for ${searchQuery}`);
        }
      } catch (error) {
        console.error(`Error fetching products for ${suggestion.title}:`, error);
      }

      this.suggestionsList[index].loading = false;
    },
    saveSuggestions() {
      // Create a blob with the suggestions data
      let content = this.suggestionsList.map(s => `${s.title}: ${s.description}`).join('\n');
      let siteURL = window.location.origin;      
      content +=`from ${siteURL} \n\n`;
      const blob = new Blob([content], { type: 'text/plain' });

      // Create a link element to download the blob as a file
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'MyRedesignedRoomRecommendations.txt';
      link.click();

      console.log('Suggestions saved successfully');
    },
    shareSuggestions() {
      // Create formatted content from suggestionsList
      let siteURL = window.location.origin;
      let content = `MyRedesignedRoom Recommendations from ${siteURL} \n\n`;
      this.suggestionsList.forEach((suggestion, index) => {
        content += `\n`;
        if (suggestion.title) {
          content += `Recommendation ${index + 1}: ${suggestion.title}\n`;
        }
        if (suggestion.description) {
          content += `${suggestion.description}\n`;
        }
        content += '\n';
      });

      // Check if Web Share API Level 2 is available and share accordingly
      if (navigator.share) {
        if (navigator.canShare) {
          navigator.share({
            title: 'MyRedesignedRoom Recommendations',
            text: content,
          })
          .then(() => console.log('Suggestions and image shared successfully'))
          .catch((error) => console.error('Error sharing suggestions and image:', error));
        } else {
          // If no image is available or sharing files is not supported, share just the text
          navigator.share({
            title: 'MyRedesignedRoom Recommendations',
            text: content,
          })
          .then(() => console.log('Suggestions shared successfully'))
          .catch((error) => console.error('Error sharing suggestions:', error));
        }
      } else {
        alert('Sharing is not supported on this browser.');
      }
    }
  },
};
</script>

<style>
.results-container {
  max-width: 900px;
  margin: 0 auto;
  text-align: center; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: center; /* Aligns buttons horizontally in the center */
  align-items: center; /* Aligns buttons vertically */
  gap: 20px; /* Adds space between buttons */
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.action-button {
  padding: 12px 20px; /* Consistent padding for buttons */
  font-size: 1.1rem;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 160px; /* Ensures all buttons have the same minimum width */
  height: 50px; /* Ensures consistent height */
  display: flex;
  align-items: center; /* Centers the button content vertically */
  justify-content: center; /* Centers the text and icons horizontally */
  margin: 0; /* Ensures no extra vertical margins */
}

/* Add margin between icons and text */
.action-button i {
  margin-right: 8px; /* Adds spacing between the icon and the text */
}


h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #1c1c1c;
  margin-bottom: 24px;
}

h4 {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.suggestion-card {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 10px;  
  width: 100%;
}

.amazon-carousel {
  display: flex;
  justify-content: center;
}

.amazon-products {
  margin: 20px 0;
}

.amazon-products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.amazon-product-card {
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center;
  transition: transform 0.3s;
}

.amazon-product-card:hover {
  transform: translateY(-5px);
}

.product-image {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
}

.product-title {
  font-size: 1rem;
  font-weight: bold;
  margin: 10px 0;
}

.product-price {
  font-size: 1.1rem;
  font-weight: bold;
  color: #ff9900;
}

.product-info {
   padding:10px; 
   text-align:center; 
}

.view-product-link {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #ff9900;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.view-product-link:hover {
  background-color: #e68a00;
}

.share-button {
   background-color:#007bff; 
   color:#fff; 
}

.share-button:hover {
   background-color:#0056b3; 
}

.save-button {
   background-color:#28a745; 
   color:#fff; 
}

.save-button:hover {
   background-color:#218838; 
}

/* General button styles */
.fetch-button {
  padding: 12px 20px; /* Ensure consistent padding */
  font-size: 1.1rem; /* Same font size as other action buttons */
  font-weight: bold; /* Make text bold */
  border: none; /* Remove default button border */
  border-radius: 30px; /* Rounded corners to match other buttons */
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff; /* White text */
  background-color: #007bff; /* Use a blue color for the button */
}

/* Button hover effect */
.fetch-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Icon spacing for consistency */
.fetch-button i {
  margin-right: 8px; /* Adds space between icon and text */
}

/* Add margin to the button */
.fetch-button {
  margin-top: 16px;
}

.restart-button {
   background-color:#6c5ce7; 
   color:#fff; 
}

.restart-button:hover {
   background-color:#4834d4; 
}

.loading-indicator {
    margin-top:16px;
    font-size:1.1rem;
}

/* Spinner */
.spinner {
    border: solid #ccc;
    border-top-color: #007bff;
    border-radius:50%;
    width:20px;
    height:20px;
    animation: spin .6s linear infinite;
    margin:auto;
}
@keyframes spin {
    to { transform: rotate(360deg); }
}

/* Ensure buttons stack nicely on smaller screens */
@media (max-width: 768px) {
  .button-container {
    flex-direction: column;
  }

  .action-button {
    width: 100%; /* Buttons take full width on small screens */
    margin-bottom: 10px; /* Add space between buttons when stacked */
  }
}
</style>