<template>
    <div v-if="trendingItems.length > 0" class="trending-carousel">
      <h2 class="trending-carousel__header">Trending Right Now</h2>
      <carousel
        :autoplay="true"
        :autoplay-interval="8000"
        :items-to-show="1.3"
        :items-to-show-desktop="3.5"
        :transition="1800"
        :pause-autoplay-on-hover="true"
        :wrap-around="true"
        class="trending-amazon-carousel"
      >
        <slide v-for="(item, index) in trendingItems" :key="index">
          <div class="trending-carousel__item">
            <img :src="item.Images.Primary.Medium.URL" :alt="item.ItemInfo.Title.DisplayValue" class="trending-carousel__image" />
            <div class="trending-carousel__info">
              <!-- Truncate title to a specific length -->
              <h3 class="trending-carousel__title">{{ truncateTitle(item.ItemInfo.Title.DisplayValue) }}</h3>
              <p class="trending-carousel__price">{{ item.Offers.Listings[0].Price.DisplayAmount }}</p>
              <a :href="item.DetailPageURL" target="_blank" class="trending-carousel__cta-button">Buy on Amazon</a>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </template>
  
  <script>
  import 'vue3-carousel/dist/carousel.css';
  import { Carousel, Slide } from 'vue3-carousel';
  import { fetchTrendingHomeItems } from '@/amazonApiHelper';
  
  export default {
    components: { Carousel, Slide },
    data() {
      return {
        trendingItems: [],
      };
    },
    methods: {
      truncateTitle(title) {
        const maxLength = 50;
        return title.length > maxLength ? title.slice(0, maxLength) + '...' : title;
      },
    },
    async created() {
      try {
        this.trendingItems = await fetchTrendingHomeItems();
      } catch (error) {
        console.error('Error fetching trending items:', error);
      }
    },
  };
  </script>
  
  <style scoped>
  .trending-carousel {
    margin: 20px auto;
    max-width: 90%;
  }
  
  .trending-carousel__header {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
    border-bottom: 3px solid #ff9900;
    display: inline-block;
    padding-bottom: 5px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .trending-carousel__header {
      font-size: 1.25rem;
    }
    .trending-carousel__item {
      padding: 10px;
      box-shadow: none;
    }
    .trending-carousel__title {
      font-size: 1rem;
      font-weight: 600;
      color: #444;
      margin-bottom: 8px;
    }
    .trending-carousel__price {
      font-size: 0.9rem;
    }
    .trending-carousel__cta-button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  }
  
  .trending-carousel__item {
    text-align: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .trending-carousel__item:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  }
  
  .trending-carousel__image {
    width: 100%;
    max-width: 100px;
    height: auto;
    margin-bottom: 15px;
    border-radius: 8px;
  }
  
  .trending-carousel__info {
    padding: 15px 0;
  }
  
  .trending-carousel__title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #444;
    margin-bottom: 10px;
  }
  
  .trending-carousel__price {
    font-size: 1rem;
    color: #666;
    margin-bottom: 15px;
  }
  
  .trending-carousel__cta-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background-color: #ff9900;
    border-radius: 8px;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .trending-carousel__cta-button:hover {
    background-color: #cc7a00;
    transform: translateY(-3px);
  }
  </style>
  