<template>
    <div class="legal-container">
      <h1>MyRedesignedRoom - Privacy Policy and Terms of Service</h1>
  
      <!-- Privacy Policy Section -->
      <section id="privacy-policy">
        <h2>Privacy Policy</h2>
        <p>Last Updated: 2024</p>
        <p>
          MyRedesignedRoom ("we", "our", "us") operates the MyRedesignedRoom application (the "App"). 
          This page informs you of our policies regarding the collection, use, and disclosure of personal data 
          when you use our App and the choices you have associated with that data.
        </p>
        <h5>
            AI Usage
        </h5>
        <p>
            We utilize artificial intelligence (AI) to analyze the photos you upload for design recommendations. 
            These AI-powered suggestions are for informational purposes only, and we do not guarantee the accuracy 
            or suitability of these recommendations for your specific needs. Your uploaded images are only used for 
            the purpose of generating these recommendations and are not stored after processing.
        </p>
        <h3>Information We Collect</h3>
        <p>We use specific information for App functionality:</p>
        <ul>
          <li><strong>Images Uploaded:</strong> The App allows you to upload photos of your room to generate personalized design suggestions.</li>
        </ul>
        <h3>How We Use Your Data</h3>
        <p>We use the data to:</p>
        <ul>
          <li>Allow you to participate in interactive features of our App</li>
          <li>Detect and prevent technical issues</li>
        </ul>
        <h5>
            Data retention
        </h5>
        <p>
            We do not store any data beyond processing time.
        </p>
        <h3>Sharing of Data</h3>
        <p>We do not sell or share your personal information with third parties, except to comply with legal obligations or protect our legal rights.</p>
  
        <h3>Security of Your Data</h3>
        <p>We use commercially acceptable means to protect your personal data.</p>
  
        <h3>Your Data Protection Rights</h3>
        <p>You have the right to request access, correction, or deletion of your data. To exercise these rights, please contact us.</p>
  
        <h3>Changes to This Privacy Policy</h3>
        <p>We may update our Privacy Policy from time to time.</p>
  
        <h3>Contact Us</h3>
        <p>If you have any questions about this Privacy Policy, please contact us.</p>
      </section>
  
      <!-- Terms of Service Section -->
      <section id="terms-of-service">
        <h2>Terms of Service</h2>
        <p>Last Updated: 2024</p>
        <h3>Acceptance of Terms</h3>
        <p>By using or accessing the App, you agree to be bound by these Terms. If you disagree, you may not use the App.</p>
  
        <h3>Intellectual Property</h3>
        <p>
          All content provided through the App is owned by MyRedesignedRoom and is protected by intellectual property laws.
        </p>
  
        <h3>Disclaimer of Warranties</h3>
        <p>The App is provided "as-is" without warranties of any kind.</p>
  
        <h3>Limitation of Liability</h3>
        <p>
        To the fullest extent permitted by law, MyRedesignedRoom and its affiliates, officers, employees, agents, 
        suppliers, and licensors shall not be held liable for any indirect, incidental, special, consequential, 
        or punitive damages, including without limitation loss of profits, data, use, goodwill, or other intangible 
        losses, resulting from (i) your access to or use of the App; (ii) any conduct or content of any third party 
        on the App; (iii) any content obtained from the App; and (iv) unauthorized access, use, or 
        alteration of your transmissions or content.
        </p>
        <h3>Disclaimer Regarding AI-Generated Suggestions</h3>
        <p>
        MyRedesignedRoom uses artificial intelligence (AI) to provide design suggestions based on the images you upload. 
        While we strive to offer helpful and personalized suggestions, these AI-generated recommendations are provided "as is" 
        and should be used for informational purposes only. We make no warranties or guarantees about the accuracy, completeness,
         or suitability of the suggestions for your specific needs. The use of any suggestions or ideas provided by the AI is at
          your own risk, and we encourage you to consult with a professional before making significant design changes.
        </p>
        <h3>Entertainment Purposes Only</h3>
        <p>
        The MyRedesignedRoom app is provided for entertainment purposes only. The AI-generated design suggestions are 
        intended to offer fun and creative ideas for enhancing your space, but they should not be relied upon for 
        making significant design or renovation decisions. We do not guarantee the accuracy, completeness, or 
        reliability of the suggestions, and any actions you take based on the suggestions are at your own risk.
         We recommend consulting a licensed professional for any major design, construction, or renovation projects.
        </p>
        <h3>Governing Law</h3>
        <p>These Terms shall be governed by the laws of the United States of America.</p>
  
        <h3>Contact Us</h3>
        <p>If you have any questions about these Terms, please contact us.</p>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: "LearnMore"
  };
  </script>
  
  <style scoped>
  .legal-container {
    padding: 20px;
    line-height: 1.6;
  }
  
  h1, h2, h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  ul {
    margin-left: 20px;
  }
  
  p {
    margin-bottom: 10px;
  }
  </style>
  